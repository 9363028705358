import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { device } from './layout/GlobalStyles';
import { getImage, StaticImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const StyledHomeGallery = styled.section`
    margin: 0 1em;
    .pic-container {
        height: 100vh;
        min-height: 610px;
        margin: 0 auto;
        position: relative;
    }
    .pic-1 {
        top: 15%;
        position: absolute;
        width: 150px;
        z-index: 3;
        height: 180px;
    }
    .pic-2 {
        top: 29%;
        position: absolute;
        width: 256px;
        z-index: 2;
        left: 15%;
        height: 265px;
    }
    .pic-3 {
        z-index: 0;
        right: 0em;
        position: absolute;
        width: 150px;
        height: 200px;
        top: 10%;
    }
    .pic-4 {
        z-index: 2;
        top: 67%;
        position: absolute;
        width: 154px;
        height: 197px;
    }
    .pic-5 {
        right: 0em;
        top: 60%;
        position: absolute;
        width: 167px;
        height: 208px;
    }
    @media ${device.mobileL} {
        .pic-container {
            width: 380px;
        }

        .pic-2 {
            height: 295px;
        }
    }
    @media ${device.tablet} {
        .pic-container {
            margin: 0 auto;
            width: 750px;
        }
        .pic-1 {
            left: 2%;
            z-index: 1;
            top: 9%;
            width: 280px;
            height: 300px;
            /* change this ones dimension */
        }
        .pic-2 {
            left: 19%;
            top: 35%;
            width: 400px;
            height: 400px;
            /* change this ones dimension */
        }
        .pic-3 {
            left: 47%;
            width: 376px;
            height: 402px;
            /* and this */
        }
        .pic-4 {
            left: 2%;
            width: 340px;
            height: 288px;
        }
        .pic-5 {
            left: 60%;
            top: 66%;
            width: 280px;
            height: 308px;
        }
    }
    /* IPAD PRO */
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        .pic-2 {
            left: 19%;
            top: 28%;
            width: 500px;
            height: 650px;
        }
        padding-bottom: 2em;
        .pic-container {
            margin: 0 auto;
            width: 1000px;
        }
        .pic-1 {
            left: 2%;
            z-index: 1;
            top: 14%;
            width: 400px;
            height: 300px;
        }

        .pic-3 {
            left: 47%;
            width: 486px;
            height: 402px;
            /* and this */
        }
        .pic-4 {
            left: 2%;
            width: 340px;
            height: 288px;
        }
        .pic-5 {
            left: 60%;
            top: 60%;
            width: 360px;
            height: 308px;
        }
    }
    @media ${device.laptop} {
        padding-bottom: 2em;
        .pic-container {
            margin: 0 auto;
            width: 1000px;
            height: 96.5vh;
        }
        .pic-1 {
            left: 10%;
            z-index: 1;
            top: 15%;
            width: 380px;
            height: 270px;
            /* change this ones dimension */
        }
        .pic-2 {
            left: 34%;
            top: 22%;
            width: 380px;
            height: 480px;
        }
        .pic-3 {
            z-index: 4;
            top: 10%;
            left: 63.5%;
            width: 336px;
            height: 272px;
            /* and this */
        }
        .pic-4 {
            left: 14%;
            top: 72%;
            width: 300px;
            height: 208px;
            z-index: 1;
        }
        .pic-5 {
            left: 60%;
            top: 60%;
            width: 370px;
            height: 278px;
        }
    }
    @media ${device.laptopL} {
        padding-bottom: 8em;
        .pic-container {
            margin: 0 auto;
            height: 94vh;
            width: 1000px;
        }
        .pic-1 {
            left: 0%;
            z-index: 1;
            top: 9%;
            width: 400px;
            height: 300px;
            /* change this ones dimension */
        }
        .pic-2 {
            top: 28%;
            width: 420px;
            left: 24%;
            height: 500px;
        }
        .pic-3 {
            right: 0%;
            top: 14%;
            width: 406px;
            height: 302px;
            /* and this */
        }
        .pic-4 {
            width: 270px;
            height: 248px;
            top: 69%;
            left: 0%;
        }
        .pic-5 {
            width: 370px;
            height: 250px;
            right: 0%;
            top: 72%;
        }
    }
    @media ${device.desktop} {
        .pic-container {
            height: 86vh;
            margin: 0 auto;
            width: 1200px;
            left: 2em;
        }
        .pic-1 {
            left: 6%;
            z-index: 1;
            top: 5%;
            width: 400px;
            height: 300px;
            /* change this ones dimension */
        }
        .pic-2 {
            top: 28%;
            width: 450px;
            left: 24%;
            height: 500px;
        }
        .pic-3 {
            left: 50%;
            top: 4%;
            width: 536px;
            height: 402px;
            /* and this */
        }
        .pic-4 {
            left: 2%;
            width: 410px;
            height: 308px;
        }
        .pic-5 {
            left: 51%;
            width: 410px;
            height: 308px;
        }
    }
`;

const StyledBackground = styled(BackgroundImage)`
    background-size: cover;
    min-height: 660px;
    @media ${device.tablet} {
        background-size: contain;
    }
`;

export const HomePhotos: React.FC = () => {
    const { rainbowBackgroundImage } = useStaticQuery(graphql`
        query {
            rainbowBackgroundImage: file(relativePath: { eq: "rainbow.jpeg" }) {
                id
                childImageSharp {
                    gatsbyImageData(width: 2000, quality: 90, webpOptions: { quality: 90 })
                }
            }
        }
    `);

    const image = getImage(rainbowBackgroundImage);
    const bgImage = convertToBgImage(image);
    return (
        <>
            <StyledBackground Tag="section" {...bgImage} preserveStackingContext>
                <StyledHomeGallery>
                    <div className="pic-container">
                        <StaticImage
                            className="pic-1"
                            quality={100}
                            alt="girl with blue background"
                            src="../images/homephotos/girl-blue-bg.jpg"
                        />
                        <StaticImage
                            quality={100}
                            className="pic-2"
                            alt="beautiful girl"
                            src="../images/homephotos/girl-brown-bg.jpg"
                        />
                        <StaticImage
                            quality={100}
                            alt="girl in front of grey background"
                            className="pic-3"
                            src="../images/homephotos/girl-grey.jpg"
                        />
                        <StaticImage
                            quality={100}
                            alt="guy in black and white"
                            className="pic-4"
                            src="../images/homephotos/guy-bw.jpg"
                        />
                        <StaticImage
                            quality={100}
                            alt="girl earrings"
                            className="pic-5"
                            src="../images/homephotos/girl-earrings.jpg"
                        />
                    </div>
                </StyledHomeGallery>
            </StyledBackground>
        </>
    );
};
