import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import logo from '../images/tarzana-logo.png';
import mobile from '../images/svgs/mobile.svg';
import marker from '../images/svgs/marker.svg';
import facebook from '../images/svgs/facebook.svg';
import instagram from '../images/svgs/instagram.svg';
import { Button } from './layout/StyledComponents';

const StyledGatsbyImage = styled(GatsbyImage)`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;

    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background-color: rgba(0,0,0,0.4);
    }
`;

const StyledHomeHero = styled.section`
    min-height: 100vh;
    padding: 0em 1em;
    color: ${colors.white};
    .play-button-wrap {
        border: none;
        background: none;
        position: relative;
        p {
            position: absolute;
            left: 15%;
            font-family: 'Bebas Neue', cursive;
            color: ${colors.white};
            font-size: 21px;
        }
    }
    h6 {
        font-size: 18px;
        text-align: center;
        font-family: 'Bebas Neue', cursive;
    }
    .logo {
        margin: 0 auto;
        padding-top: 13em;
        padding-bottom: 1em;
        border-bottom: 2px solid ${colors.white};
        width: 400px;
        height: auto;
    }
    h2 {
        margin: 1em 0em 1em;
        text-align: center;
        color: ${colors.white};
        font-size: 24px;

        font-weight: 500;
        line-height: 155.7%;
        letter-spacing: 0.19em;
    }
    .sub-header-mobile {
        justify-content: space-around;
        max-width: 320px;
        margin: 0 auto;
        display: flex;

        > a {
         color: white;
         }
        img {
            width: auto;
            margin: 1em auto;
        }
        .mobile {
            height: 20px;
        }
        .marker {
            height: 25px;
        }
    }
    .social {
        img:first-child {
            margin-top: 2em;
            margin-bottom: 2em;
        }
    }
    .buttons {
        margin-bottom: 3.5em;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        img {
            height: 44px;
            padding: 0.3em;
            margin: 0em 2em 0em 2em;
        }
    }
    .button-flex {
        display: flex;
        align-items: flex-end;
    }
    @media ${device.mobileL} {
        .buttons {
            margin-bottom: 2.5em;
        }
    }
    @media ${device.tablet} {
        .social {
            img:first-child {
                margin-top: 0em;
                margin-bottom: 4em;
            }
        }
        .play-button-wrap {
            p {
                left: 45%;
                bottom: -8%;
                font-size: 22px;
            }
        }
        padding: 0em 2em;
        .logo {
            padding-top: 15em;
            padding-bottom: 3.3em;
            border-bottom: none;
        }
        h2 {
            border-top: 1px solid ${colors.white};
            font-size: 38px;
            margin: 0 auto;
            padding-top: 1.33em;
            width: 624px;
        }
        .social {
            margin-left: 1em;
        }
        .buttons {
            padding: 0em;
            margin-top: 10em;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: space-between;
            img {
                height: 148px;
                width: auto;
                margin: 0em 2em 0em 6em;
                padding: 2em;
            }
            div {
                width: 60%;
                margin: 13em;
                position: absolute;
                height: 3px;
                background: ${colors.white};
            }
        }
        .sub-header-mobile {
            display: none;
        }
    }
    @media ${device.laptop} {
        h2 {
            font-size: 48px;
        }
        .buttons {
            margin-top: 0em;
            img {
                height: 148px;
            }
        }
        .logo {
            padding-top: 12em;
            width: 700px;
        }
    }
    @media ${device.desktop} {
        .buttons {
            div {
                width: 70%;
            }
        }
        .logo {
            padding-top: 15em;
            width: 900px;
        }
    }
    @media ${device.desktopL} {
        position: relative;
    }
`;

const StyledButton = styled(Button)``;

export const HomeHero: React.FC = () => {
    const { desktopImage, mobileImage } = useStaticQuery(graphql`
        query {
            desktopImage: file(relativePath: { eq: "test-bg.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(quality: 90, webpOptions: { quality: 90 })
                }
            }
            mobileImage: file(relativePath: { eq: "mobile-bg.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(quality: 90, webpOptions: { quality: 90 })
                }
            }
        }
    `);

    const images = withArtDirection(getImage(desktopImage.childImageSharp.gatsbyImageData), [
        {
            media: '(max-width: 767px)',
            image: getImage(mobileImage.childImageSharp.gatsbyImageData),
            alt: 'group photo of the dentist team',
        },
    ]);

    // const image = getImage(desktopImage);
    // const bgImage = convertToBgImage(image);

    return (
        <>
            <div style={{position: 'relative'}}>
                <StyledGatsbyImage alt="girl lying on couch" image={images} />
                <StyledHomeHero>
                    <img src={logo} alt="vytal logo" className="logo" />
                    <h2>WELCOMING NEW PATIENTS</h2>
                    <div className="sub-header-mobile">
                        <a href="tel:+18185785125">
                            <img className="mobile" src={mobile} alt="telephone icon" />
                            <h6>(818) 578 5125</h6>
                        </a>
                        <div>
                            <img className="marker" src={marker} alt="map marker icon" />
                            <h6>
                                19228 Ventura Blvd, Suite <br />
                                A,Tarzana, CA 91356
                            </h6>
                        </div>
                    </div>
                    <div className="button-flex">
                        <div className="social">
                            <a href="https://www.facebook.com/vytalcenter/" target="__blank">
                                <img className="facebook" src={facebook} alt="facebook logo" />
                            </a>
                            <a href="https://www.instagram.com/vytalcenter/?hl=en" target="__blank">
                                <img className="instagram" src={instagram} alt="instagram logo" />
                            </a>
                        </div>
                        <div className="buttons">
                            <button className="play-button-wrap"></button>
                            <div></div>

                            <Link to="book-appointment">
                                <StyledButton color={`${colors.white}`}>
                                    BOOK AN APPOINTMENT
                                </StyledButton>
                            </Link>
                        </div>
                    </div>
                </StyledHomeHero>
            </div>
        </>
    );
};
