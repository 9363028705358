import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { colors, device } from '../GlobalStyles';
import styled from '@emotion/styled';
import mobile from '../../../images/svgs/mobile.svg';
import marker from '../../../images/svgs/marker.svg';
import { NavMenuSlide } from './NavMenuSlide';
import { Button } from '../StyledComponents';
const StyledNav = styled.nav`
    .clear {
        background: transparent;
    }
    .blue {
        background: linear-gradient(
            321.57deg,
            rgba(0, 0, 0, 0.62) 0%,
            rgba(188, 142, 71, 0.62) 144.15%
        );
    }
    transition: 2s ease-in;
    position: fixed;
    z-index: 1;
    color: ${colors.white};
    top: 0;
    display: flex;
    z-index: 300;
    width: 100vw;
    header {
        z-index: 3;
    }
    nav {
        z-index: 3;
        height: 12vh;
        min-height: 75px;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: space-around;
        h3 {
            cursor: pointer;
            z-index: 3;
            width: 245px;
            font-family: 'Antonio';
            font-size: 24px;
            font-weight: 300;
            line-height: 34.62px;
        }
        .burger-mobile {
            cursor: pointer;
            button {
                border: none;
                background: none;
            }
        }
        .burger-desktop {
            display: none;
            button {
                cursor: pointer;
                border: none;
                background: none;
            }
        }
        .burger-mobile,
        .burger-desktop {
            div {
                margin: 12px 0px;
                background: white;
                height: 2px;
                width: 60px;
            }
        }
    }
    .nav-desktop-buttons {
        display: none;
    }
    @media ${device.laptop} {
        h6 {
            font-size: 18px;
            text-align: center;
            font-family: 'Bebas Neue', cursive;
        }
        .nav-desktop-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 677px;
            div {
                display: flex;
            }
            h6:first-of-type {
                font-weight: 400;
                margin-left: 0.5em;
            }
        }
        nav {
            max-height: 102px;
        }
    }
    @media ${device.laptop} {
        justify-content: center;
        .desktop-line-flex {
            align-items: flex-end;
            justify-content: space-between;
            width: 320px;
            display: flex;
        }
        nav {
            padding: 0em 2em;
            justify-content: space-between;
            h3 {
                width: 200px;
            }
            .burger-mobile {
                display: none;
            }
            .burger-desktop {
                display: inline-block;
            }
        }
    }
    @media ${device.laptopL} {
        .nav-desktop-buttons {
            width: 677px;
        }
    }
    @media ${device.desktopL} {
        .nav-desktop-buttons {
            width: 677px;
        }
    }
`;

const Line = styled.div`
    .line {
        display: none;
    }
    @media ${device.laptop} {
        .line {
            display: block;
            position: absolute;
            background: white;
            width: 3px;
            z-index: 3;
            margin-left: 8em;
            min-height: 100vh;
        }
    }
`;

const StyledNavButton = styled(Button)``;

const debounce = (func, ms) => {
    let timer;
    return (...args) => {
        if (!timer) {
            timer = setTimeout(() => {
                timer = undefined;
                func.apply(this, args);
            }, ms);
        }
    };
};

export const Nav: React.FC = () => {
    const isSSR = typeof window === 'undefined';

    const [navClassName, setNavClassName] = useState('');
    const [position, setPosition] = useState(isSSR ? 0 : window.scrollY);

    const debounceMs = 200;

    const onScroll = useCallback(
        debounce(() => setPosition(window.scrollY), 300),
        [debounceMs, setPosition]
    );
    console.log(position, navClassName);

    useEffect(() => {
        if (isSSR) return;
        window.addEventListener('scroll', onScroll);
        if (position > 700) {
            setNavClassName('blue');
        } else {
            setNavClassName('clear');
        }
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [position]);

    const [navActive, setNavActive] = useState<boolean>(false);
    return (
        <Line>
            <NavMenuSlide navActive={navActive} setNavActive={setNavActive} />
            <div className="line"></div>
            <StyledNav>
                <header>
                    <nav className={navClassName}>
                        <div className="desktop-line-flex">
                            <div className="burger-desktop">
                                <button onClick={() => setNavActive(navActive => !navActive)}>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </button>
                            </div>

                            <Link
                                onClick={() => setNavActive(false)}
                                style={{ color: `${colors.white}` }}
                                to="/"
                            >
                                <h3>TARZANA OUTPATIENT SURGICAL INSTITUTE</h3>
                            </Link>
                        </div>
                        <div className="burger-mobile">
                            <button onClick={() => setNavActive(navActive => !navActive)}>
                                <div></div>
                                <div></div>
                                <div></div>
                            </button>
                        </div>

                        <div className="nav-desktop-buttons">
                            <Link to="/contact">
                                <StyledNavButton color={`${colors.white}`}>
                                    Contact Us
                                </StyledNavButton>
                            </Link>
                            <div>
                                <img src={mobile} alt="mobile phone" />
                                <a style={{ color: 'white' }} href="tel:8185785125">
                                    <h6>(818) 578 5125</h6>
                                </a>
                            </div>
                            <div>
                                <img src={marker} alt="map marker" />
                                <a
                                    style={{ color: 'white' }}
                                    target="_blank"
                                    href="https://www.google.com/maps/place/19228+Ventura+Blvd+a,+Tarzana,+CA+91356,+USA/@52.676695,-7.8065301,14z/data=!3m1!4b1!4m2!3m1!1s0x80c299148b367eb7:0x69910eea314ee511"
                                    rel="noreferrer"
                                >
                                    <h6>19228 Ventura Blvd, Suite A,Tarzana, CA 91356</h6>
                                </a>
                            </div>
                        </div>
                    </nav>
                </header>
            </StyledNav>
        </Line>
    );
};
